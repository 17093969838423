import React, { Suspense } from "react";
import { makeStyles } from "@material-ui/core/styles";

import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Loading from "../UX/Loading";
import Typography from "@material-ui/core/Typography";
import tumbnail from '../../assets/tumbnail-min.jpg'

const VideoPlayer = React.lazy(() => import("../UX/VideoPlayer"));

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up("md")]: {
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(5),
    },
    [theme.breakpoints.down("sm")]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
  },
  heading: {
    [theme.breakpoints.up("md")]: {
      fontSize: "2.25rem",
      paddingBottom: theme.spacing(3),
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.8rem",
      paddingBottom: theme.spacing(3),
    },
    color: "#b53636",
  },
  paragraph: {
    paddingTop: theme.spacing(3),
    [theme.breakpoints.up("md")]: {
      fontSize: "1.2rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.9rem",
    },
  },
}));

export default function Hero() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Container maxWidth="md">
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="center"
          wrap
        >
          <Grid item>
            <Typography className={classes.heading} variant="h1" align="center">
              Pembuluh Darah Tersumbat?
            </Typography>
          </Grid>
          <Grid item>
            <Suspense fallback={Loading}>
              <VideoPlayer link="https://www.youtube.com/watch?v=njT428_JYzI" tumb={tumbnail}/>
            </Suspense>
          </Grid>
          <Grid item>
            <Typography
              className={classes.paragraph}
              variant="body2"
              align="justify"
            >
              Atherosclerosis merupakan kondisi gangguan yang terjadi pada
              jaringan pembuluh darah tubuh kita. Gangguan ini disebabkan oleh
              peradangan yang disebabkan oleh beberapa faktor diantaranya
              merokok, gula darah tinggi, tekanan darah tinggi, asam urat,
              lemak, dan kolesterol jahat yang melukai dinding pembuluh darah
              sehingga menimbulkan plak yang dapat mengakibatkan pembuluh darah
              tersumbat sebagian ataupun menyeluruh.
            </Typography>
            <Typography
              className={classes.paragraph}
              variant="body2"
              align="justify"
            >
              Lokasi terbentuknya sumbatan pembuluh darah menyebabkan sel tubuh
              di sekitar lokasi sumbatan tidak memperoleh asupan oksigen dan
              nutrisi yang cukup dan menyebabkan terjadi kerusakan pada sel
              tersebut dan memicu berbagai gejala penyakit diantaranya rasa
              sakit di bagian tubuh tertentu, mudah letih dalam beraktivitas,
              hingga serangan jantung serta stroke yang sangat membahayakan bagi
              keselamatan jiwa.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
