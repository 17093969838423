import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Box";

// Inspired by the former Facebook spinners.
const useStylesCircle = makeStyles((theme) => ({
  root: {
    position: "relative",
    minHeight: "100%",
  },
  bottom: {
    color: "#e0e0e0",
  },
  top: {
    color: "#b53636",
    animationDuration: "900ms",
    position: "absolute",
    left: 0,
  },
  circle: {
    strokeLinecap: "round",
  },
}));

function LoadingCircle(props) {
  const classes = useStylesCircle();

  return (
    <div className={classes.root}>
      <CircularProgress
        variant="determinate"
        className={classes.bottom}
        size={40}
        thickness={4}
        {...props}
        value={100}
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        className={classes.top}
        classes={{
          circle: classes.circle,
        }}
        size={40}
        thickness={4}
        {...props}
      />
    </div>
  );
}

export default function Loading() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <LoadingCircle />
    </div>
  );
}
