import React, { Suspense, useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

import styled from "styled-components";
import heroImage from "../assets/heroImage-min.jpg";
import mobileHero from "../assets/mobileHero-min.jpg";
import Container from "@material-ui/core/Container";
import Hero from "./layouts/Hero";
import Hidden from "@material-ui/core/Hidden";
import Loading from "./UX/Loading";
import ScrollToTop from "./UX/ScrollToTop";
import ToTopButton from "./UX/ToTopButton";

const Product = React.lazy(() => import("./layouts/Product"));
const Certificate = React.lazy(() => import("./layouts/Certificate"));
const Ingredients = React.lazy(() => import("./layouts/Ingredients"));
const Manfaat = React.lazy(() => import("./layouts/Manfaat"));
const Offering = React.lazy(() => import("./layouts/Offering"));
const Questions = React.lazy(() => import("./layouts/Questions"));
const Footer = React.lazy(() => import("./layouts/Footer"));

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down("sm")]: {
      overflowX: "hidden",
    },
  },
}));

export default function Homepage() {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);

  const Refresh = () =>
    (window.onbeforeunload = function () {
      window.scrollTo(0, 0);
    });

  const fetchHero = async () => {
    setLoading(true);
    const response1 = await fetch(heroImage);
    const response2 = await fetch(mobileHero);
    setLoading(false);
  };

  useEffect(() => {
    fetchHero();
  }, []);

  const StyledContainer = styled(Container)`
    & > div {
      width: 100%;
      height: 100vh;
    }
  `;

  if (loading) {
    return (
      <StyledContainer>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Loading />
        </div>
      </StyledContainer>
    );
  }

  return (
    <div className={classes.root}>
      <Hidden xsDown>
        <img src={heroImage} width="100%" alt="Hero Image" />
      </Hidden>
      <Hidden smUp>
        <img src={mobileHero} width="100%" alt="Hero Image" />
      </Hidden>
      <Suspense fallback={Loading}>
        <Hero />
        <Refresh />
        <Product />
        <Suspense fallback={Loading}>
          <Certificate />
          <Manfaat />
          <Ingredients />
          <Suspense fallback={Loading}>
            <Offering />
            <Questions />
            <ToTopButton />
            <Footer />
          </Suspense>
        </Suspense>
      </Suspense>
      <ScrollToTop />
    </div>
  );
}
