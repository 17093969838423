import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import AddIcon from "@material-ui/icons/Add";
import Button from "@material-ui/core/Button";
import toTop from "../../assets/toTop.png";

const useStyles = makeStyles((theme) => ({
  buttonWrapper: {
    position: "fixed",
    right: "10px",
    bottom: "100px",
    color: "#b53636",
		padding: 0,
  },
	button:{[theme.breakpoints.up("sm")]: {
		width:'70px',
	},
	[theme.breakpoints.down("xs")]: {
		width:'60px',
	},
	}
}));

export default function ToTopButton() {
  const classes = useStyles();

  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
      /* you can also use 'auto' behaviour
		in place of 'smooth' */
    });
  };

  window.addEventListener("scroll", toggleVisible);

  return (
      <Button className={classes.buttonWrapper} >
        <img className={classes.button}
				src={toTop}
				title='Scroll to Top'
				alt='Scroll to Top'
          onClick={scrollToTop}
					style={{ display: visible ? "inline" : "none" }}
        />
      </Button>
  );
}
