import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ReactGA from "react-ga";

import Homepage from "./components/Homepage";



export default function App() {
  useEffect(() => {
    ReactGA.initialize('G-EP8RDEHDYT')
    //to report page view
    ReactGA.pageview(window.location.pathname + window.location.search)
  }, []);

  return (
    <>
      <style jsx global>{`
        body {
          margin: 0px;
          padding: 0px;
        }
      `}</style>
      <div>
        <div className="App">
          {/*<Navigation id='nav'/>*/}
          <div />
          <Switch>
            <Route exact path="/" component={Homepage} />
            <Route component={Homepage} />
          </Switch>
        </div>
      </div>
    </>
  );
}
